.projectContainer {
  background-image: url("../../public/img.png"); /* 设置背景图片的路径 */
  background-size: 100% auto; /* 拉伸图片宽度到100%，高度自动调整 */
  background-position: center; /* 居中对齐背景图片 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
  width: 100vw; /* 确保容器占满整个宽度 */
  height: 100vh; /* 确保容器占满整个视口高度 */
  position: relative;
  .screen {
    width: 80%;
    height: 95%;
    position: absolute;
    top: 1%;
    left: 10%;
    background-color: rgb(200, 200, 200);
    z-index: 998;
    border-left: 50px solid black;
    border-right: 50px solid black;
    border-top: 20px solid black;
    border-bottom: 20px solid black;
    .sliderContainer {
      width: 100%;
      height: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
      .backgroundImg {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        filter: blur(4px) grayscale(40%);
        z-index: -1;
      }
      .slideContainer {
        flex: 1;
        position: relative;
        .imgContainer {
          position: absolute;
          height: 27%;
          width: 45%;
          left: 10%;
          top: 2%;
          transition: all 0.5s ease;
          overflow: hidden;
          border: 1px solid rgb(188, 188, 188);
          &.active {
            left: 5%;
            height: 50%;
            width: 90%;
            border: 3px solid rgb(108, 108, 108);
          }
          .imgBlock {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .descContainer {
          position: absolute;
          height: 30%;
          width: 30%;
          right: 10%;
          top: 2%;
          transition: all 0.5s ease;
          &.active {
            width: 1%;
          }
          h3 {
            font-size: 20px;
            font-weight: 800;
            color: rgb(199, 199, 199);
          }
          .iconContainer {
            margin-top: 5px;
            display: flex;
            gap: 10px;
            span {
            }
          }
        }
        .titleContainer {
          position: absolute;
          bottom: 10%;
          left: 10%;
          right: 10%;
          height: 55%;
          transition: all 0.5s ease;
          &.active {
            height: 25%;
            .shortDesc {
              font-size: 20px;
              font-weight: 400;
              color: rgb(227, 227, 227);
            }
          }
          .discriptionContainer {
            height: 100%;
            font-size: 20px;
            font-weight: 500;
            color: rgb(227, 227, 227);
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
          }
        }
      }
    }
    .leftArrow {
      position: absolute;
      left: 0;
      top: 50%;
      color: red;
    }
    .rightArrow {
      position: absolute;
      right: 0;
      top: 50%;
      color: red;
    }
    .back {
      position: absolute;
      bottom: 0;
    }
    .buttonContainer {
      display: flex;
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .dotsContainer {
      position: absolute;
      min-width: 160px;
      min-height: 40px;
      display: flex;
      gap: 5px;
      bottom: 0;
      z-index: 998;
      left: calc(50% - 80px);
      .dot {
        flex: 1;
        background-image: url("../../public/pokemonballoff.png");
        background-size: cover;
        background-position: center;
        &.active {
          background-image: url("../../public/pokemonballon.png");
        }
      }
    }
  }
}
