.buttonContainer {
  z-index: 999;
  .customButton {
    outline: none;
    background-color: rgb(50, 48, 68);
    position: relative;
    padding: 10px 20px;
    border: 4px solid rgb(14, 11, 38);
    box-shadow: inset 0 0 0 4px rgb(39, 37, 65), inset 0 0 0 6px rgb(34, 32, 64),
      inset 0 0 0 8px rgb(59, 56, 91);
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    &:active {
      transform: translateY(2px);
      box-shadow: inset 0 0 0 3px rgb(39, 37, 65),
        inset 0 0 0 5px rgb(34, 32, 64), inset 0 0 0 9px rgb(59, 56, 91);
    }

    .content {
      font-family: VT323;
      font-weight: 600;
      font-size: 20px;
      color: white;
      text-shadow: 2px 2px grey;
    }
    .dot1 {
      content: "";
      position: absolute;
      top: -4px;
      left: -4px;
      width: 2px;
      height: 2px;
      background-color: rgb(53, 45, 61);
      z-index: 100;
    }
    .dot2 {
      content: "";
      position: absolute;
      top: -2px;
      left: -4px;
      width: 2px;
      height: 2px;
      background-color: rgb(53, 45, 61);
      z-index: 100;
    }
    .dot3 {
      content: "";
      position: absolute;
      top: -4px;
      left: -2px;
      width: 2px;
      height: 2px;
      background-color: rgb(53, 45, 61);
      z-index: 100;
    }
    .dot4 {
      content: "";
      position: absolute;
      top: 0px;
      left: 2px;
      width: 2px;
      height: 2px;
      background-color: rgb(39, 37, 65);
      z-index: 100;
    }
    .dot5 {
      content: "";
      position: absolute;
      top: 2px;
      left: 0px;
      width: 2px;
      height: 2px;
      background-color: rgb(39, 37, 65);
      z-index: 100;
    }
    .dot6 {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 2px;
      height: 2px;
      background-color: rgb(24, 24, 24);
      z-index: 100;
    }
    .dot7 {
      content: "";
      position: absolute;
      top: -4px;
      right: -4px;
      width: 2px;
      height: 2px;
      background-color: rgb(53, 45, 61);
      z-index: 100;
    }
    .dot8 {
      content: "";
      position: absolute;
      top: -2px;
      right: -4px;
      width: 2px;
      height: 2px;
      background-color: rgb(53, 45, 61);
      z-index: 100;
    }
    .dot9 {
      content: "";
      position: absolute;
      top: -4px;
      right: -2px;
      width: 2px;
      height: 2px;
      background-color: rgb(53, 45, 61);
      z-index: 100;
    }
    .dot10 {
      content: "";
      position: absolute;
      top: 0px;
      right: 2px;
      width: 2px;
      height: 2px;
      background-color: rgb(39, 37, 65);
      z-index: 100;
    }
    .dot11 {
      content: "";
      position: absolute;
      top: 2px;
      right: 0px;
      width: 2px;
      height: 2px;
      background-color: rgb(39, 37, 65);
      z-index: 100;
    }
    .dot12 {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      width: 2px;
      height: 2px;
      background-color: rgb(24, 24, 24);
      z-index: 100;
    }
    .dot13 {
      content: "";
      position: absolute;
      bottom: -4px;
      left: -4px;
      width: 2px;
      height: 2px;
      background-color: rgb(53, 45, 61);
      z-index: 100;
    }
    .dot14 {
      content: "";
      position: absolute;
      bottom: -2px;
      left: -4px;
      width: 2px;
      height: 2px;
      background-color: rgb(53, 45, 61);
      z-index: 100;
    }
    .dot15 {
      content: "";
      position: absolute;
      bottom: -4px;
      left: -2px;
      width: 2px;
      height: 2px;
      background-color: rgb(53, 45, 61);
      z-index: 100;
    }
    .dot16 {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 2px;
      width: 2px;
      height: 2px;
      background-color: rgb(39, 37, 65);
      z-index: 100;
    }
    .dot17 {
      content: "";
      position: absolute;
      bottom: 2px;
      left: 0px;
      width: 2px;
      height: 2px;
      background-color: rgb(39, 37, 65);
      z-index: 100;
    }
    .dot18 {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 2px;
      height: 2px;
      background-color: rgb(24, 24, 24);
      z-index: 100;
    }
    .dot19 {
      content: "";
      position: absolute;
      bottom: -4px;
      right: -4px;
      width: 2px;
      height: 2px;
      background-color: rgb(53, 45, 61);
      z-index: 100;
    }
    .dot20 {
      content: "";
      position: absolute;
      bottom: -2px;
      right: -4px;
      width: 2px;
      height: 2px;
      background-color: rgb(53, 45, 61);
      z-index: 100;
    }
    .dot21 {
      content: "";
      position: absolute;
      bottom: -4px;
      right: -2px;
      width: 2px;
      height: 2px;
      background-color: rgb(53, 45, 61);
      z-index: 100;
    }
    .dot22 {
      content: "";
      position: absolute;
      bottom: 0px;
      right: 2px;
      width: 2px;
      height: 2px;
      background-color: rgb(39, 37, 65);
      z-index: 100;
    }
    .dot23 {
      content: "";
      position: absolute;
      bottom: 2px;
      right: 0px;
      width: 2px;
      height: 2px;
      background-color: rgb(39, 37, 65);
      z-index: 100;
    }
    .dot24 {
      content: "";
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 2px;
      height: 2px;
      background-color: rgb(24, 24, 24);
      z-index: 100;
    }
  }
}
